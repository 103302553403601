import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Game } from 'pages/game.jsx';
import { Page404 } from 'pages/404.jsx';
import { achievements } from 'services/achievements';
import { achieveMessage } from 'components/achieveMessage/achieveMessage';

export const Router = () => {
  achievements.onAchieved = achieveMessage;
  return (
    <Routes>
      <Route index element={<Game />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};
