const assets = {
  head: [
    [0,0,0,1],
    [0,1,1,0],
    [0,1,1,1],
    [0,0,0,0],
  ],
  headOpen: [
    [0,1,0,1],
    [0,0,1,0],
    [0,0,1,1],
    [0,1,0,0],
  ],
  body: [
    [0,0,0,0],
    [1,0,1,1],
    [1,1,0,1],
    [0,0,0,0],
  ],
  band: [
    [0,0,0,0],
    [1,1,0,0],
    [1,0,1,0],
    [0,1,1,0],
  ],
  tail: [
    [0,0,0,0],
    [1,1,0,0],
    [1,1,1,1],
    [0,0,0,0],
  ],
  eaten: [
    [0,1,1,0],
    [1,0,1,1],
    [1,1,0,1],
    [0,1,1,0],
  ],
  eatenBand: [
    [0,0,0,0],
    [1,1,0,0],
    [1,0,1,0],
    [1,1,1,0],
  ],
  apple: [
    [0,1,0,0],
    [1,0,1,0],
    [0,1,0,0],
    [0,0,0,0],
  ],
  bugs: [
    [
      [0, 0, 1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1],
      [1, 0, 1, 1, 1, 1, 0, 1],
      [1, 0, 1, 0, 0, 1, 0, 1],
    ],
    [
      [0, 0, 0, 0, 1, 1, 0, 0],
      [1, 0, 0, 1, 1, 0, 1, 0],
      [1, 0, 1, 1, 1, 1, 1, 0],
      [0, 1, 1, 1, 1, 1, 1, 1],
    ],
    [
      [0, 1, 0, 1, 0, 1, 0, 0],
      [1, 0, 1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 1, 0, 0, 1, 0, 0],
    ],
    [
      [1, 1, 0, 0, 0, 1, 0, 0],
      [1, 1, 0, 0, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 1, 0, 1, 0],
    ],
    [
      [0, 0, 0, 0, 0, 0, 0, 0],
      [1, 0, 0, 0, 0, 0, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 0, 1, 0, 1, 0, 1],
    ],
  ],
  labyrinthHeads: [
    [6, 4],
    [12, 4],
    [6, 4],
    [6, 5],
    [10, 7],
    [6, 4],
  ],
  symbols: {
    'a': [
      [0,1,0],
      [1,0,1],
      [1,1,1],
      [1,0,1],
      [1,0,1],
    ],
    'b': [
      [1,1,0],
      [1,0,1],
      [1,1,0],
      [1,0,1],
      [1,1,0],
    ],
    'c': [
      [0,1,0],
      [1,0,1],
      [1,0,0],
      [1,0,1],
      [0,1,0],
    ],
    'd': [
      [1,1,0],
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [1,1,0],
    ],
    'e': [
      [1,1,1],
      [1,0,0],
      [1,1,1],
      [1,0,0],
      [1,1,1],
    ],
    'f': [
      [1,1,0],
      [1,0,0],
      [1,1,0],
      [1,0,0],
      [1,0,0],
    ],
    'g': [
      [1,1,1],
      [1,0,0],
      [1,1,1],
      [1,0,1],
      [1,1,0],
    ],
    'h': [
      [1,0,1],
      [1,0,1],
      [1,1,1],
      [1,0,1],
      [1,0,1],
    ],
    'i': [
      [1,1,1],
      [0,1,0],
      [0,1,0],
      [0,1,0],
      [1,1,1],
    ],
    'j': [
      [0,1,1],
      [0,0,1],
      [0,0,1],
      [1,0,1],
      [0,1,0],
    ],
    'k': [
      [1,0,1],
      [1,1,0],
      [1,0,0],
      [1,1,0],
      [1,0,1],
    ],
    'l': [
      [1,0,0],
      [1,0,0],
      [1,0,0],
      [1,0,0],
      [1,1,1],
    ],
    'm': [
      [1,0,1],
      [1,1,1],
      [1,0,1],
      [1,0,1],
      [1,0,1],
    ],
    'n': [
      [0,0,1],
      [1,0,1],
      [1,1,1],
      [1,0,1],
      [1,0,0],
    ],
    'o': [
      [0,1,0],
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [0,1,0],
    ],
    'p': [
      [1,1,0],
      [1,0,1],
      [1,1,0],
      [1,0,0],
      [1,0,0],
    ],
    'q': [
      [0,1,0],
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [0,1,1],
    ],
    'r': [
      [1,1,0],
      [1,0,1],
      [1,1,0],
      [1,0,1],
      [1,0,1],
    ],
    's': [
      [0,1,1],
      [1,0,0],
      [0,1,0],
      [0,0,1],
      [1,1,0],
    ],
    't': [
      [1,1,1],
      [0,1,0],
      [0,1,0],
      [0,1,0],
      [0,1,0],
    ],
    'u': [
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [1,1,1],
    ],
    'v': [
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [0,1,0],
    ],
    'w': [
      [1,0,1],
      [1,0,1],
      [1,0,1],
      [1,1,1],
      [1,0,1],
    ],
    'x': [
      [1,0,1],
      [1,0,1],
      [0,1,0],
      [1,0,1],
      [1,0,1],
    ],
    'y': [
      [1,0,1],
      [1,0,1],
      [0,1,0],
      [0,1,0],
      [0,1,0],
    ],
    'z': [
      [1,1,1],
      [0,0,1],
      [0,1,0],
      [1,0,0],
      [1,1,1],
    ],
  },
  labyrinth: [
    [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    ],
    [
      [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
    ],
    [
      [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
    ],
    [
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0],
      [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
      [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    ],
    [
      [1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0],
      [1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0],
    ],
  ],
  numbers: [
    [
      [1, 1, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 0, 1],
      [1, 1, 1],
    ],
    [
      [0, 1, 0],
      [1, 1, 0],
      [0, 1, 0],
      [0, 1, 0],
      [0, 1, 0],
    ],
    [
      [1, 1, 1],
      [0, 0, 1],
      [1, 1, 1],
      [1, 0, 0],
      [1, 1, 1],
    ],
    [
      [1, 1, 1],
      [0, 0, 1],
      [1, 1, 1],
      [0, 0, 1],
      [1, 1, 1],
    ],
    [
      [1, 0, 1],
      [1, 0, 1],
      [1, 1, 1],
      [0, 0, 1],
      [0, 0, 1],
    ],
    [
      [1, 1, 1],
      [1, 0, 0],
      [1, 1, 1],
      [0, 0, 1],
      [1, 1, 1],
    ],
    [
      [1, 1, 1],
      [1, 0, 0],
      [1, 1, 1],
      [1, 0, 1],
      [1, 1, 1],
    ],
    [
      [1, 1, 1],
      [0, 0, 1],
      [0, 1, 0],
      [0, 1, 0],
      [0, 1, 0],
    ],
    [
      [1, 1, 1],
      [1, 0, 1],
      [1, 1, 1],
      [1, 0, 1],
      [1, 1, 1],
    ],
    [
      [1, 1, 1],
      [1, 0, 1],
      [1, 1, 1],
      [0, 0, 1],
      [1, 1, 1],
    ],
  ]
};

export { assets };
