import { useCallback, useEffect, useRef } from 'react';
import { bindRender } from './render';
import isMobile from 'is-mobile';

import cn from './game.module.less';
import { Button } from 'antd';

const Game = ({ frontend }) => {
  const ref = useRef(null);
  const render = useRef(null);
  const onNext = useCallback(() => {
    if (frontend.page !== 'game') {
      frontend.onNextPage();
      return;
    }
  }, [frontend]);
  useEffect(() => {
    if (ref.current === null || render.current !== null) {
      return;
    }
    render.current = bindRender(ref, frontend);
    frontend.update = render.current;
    
    window.addEventListener('resize', render.current, true);

    if (!isMobile()) {
      ref.current.contentEditable = 'true';
      ref.current.focus();
      ref.current.addEventListener('blur', () => {
        if (ref.current) {
          frontend.onPause();
        }
      });
    }
    ref.current.addEventListener('keydown', ({ key }) => {
      switch (key.toLowerCase()) {
      case 'arrowleft':
      case 'd':
        frontend.onLeft();
        return;
      case 'arrowright':
      case 'a':
        frontend.onRight();
        return;
      case 'arrowup':
      case 'w':
        frontend.onUp();
        return;
      case 'arrowdown':
      case 's':
        frontend.onDown();
        return;
      case 'enter':
      case ' ':
        onNext();
        return;
      // eslint-disable-next-line no-fallthrough
      case 'p':
        if (frontend.isPaused()) {
          frontend.onResume();
        } else {
          frontend.onPause();
        }
        return;
      case 'backspace':
      case 'escape':
        frontend.onBackPage();
        return;
      default:
        return;
      }
    });
    render.current();
  }, [frontend, onNext]);
  if (isMobile()) {
    return (
      <div className={cn.mobile}>
        <Button onClick={frontend.onCounterclockwise} className={cn.button}>L</Button>
        <canvas onClick={onNext} className={cn.scene} ref={ref}></canvas>
        <Button onClick={frontend.onClockwise} className={cn.button}>R</Button>
      </div>
    );
  }
  return <canvas className={cn.scene} ref={ref} contentEditable></canvas>;
};

export { Game };
